import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { type ActionFunctionArgs, json, redirect } from "@remix-run/cloudflare";
import { getValidatedFormData } from "remix-hook-form";
import { createSupabaseServerClient } from "~/lib/supabase.server";
import { Label } from "~/components/ui/label";
import {
    Form,
    useActionData,
    useNavigation,
    useLoaderData,
    type MetaFunction,
} from "@remix-run/react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Turnstile } from "@marsidev/react-turnstile";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { createBrowserClient } from "@supabase/ssr";
import { SiGoogle, SiLinkedin } from "@icons-pack/react-simple-icons";
import { Award, CheckCircle, Clock, Sparkles, Target } from "lucide-react";

export const meta: MetaFunction = () => {
    return [{ title: "Sign in | TailoredCV.ai" }];
};

const formSchema = z.object({
    email: z.string().email(),
    captchaToken: z.string(),
});

export async function loader({ context }: ActionFunctionArgs) {
    return json({
        ENV: {
            VITE_TURNSTILE_SITE_KEY: context.cloudflare.env.VITE_TURNSTILE_SITE_KEY,
            VITE_SUPABASE_URL: context.cloudflare.env.VITE_SUPABASE_URL,
            VITE_SUPABASE_ANON_KEY: context.cloudflare.env.VITE_SUPABASE_ANON_KEY,
        },
    });
}

export async function action({ request, context }: ActionFunctionArgs) {
    const {
        errors,
        data,
        receivedValues: defaultValues,
    } = await getValidatedFormData<z.infer<typeof formSchema>>(request, zodResolver(formSchema));

    if (errors) {
        return json({ errors, defaultValues, supaBaseError: "" });
    }

    const { supabase, headers } = createSupabaseServerClient({
        request,
        supabaseUrl: context.cloudflare.env.VITE_SUPABASE_URL,
        supabaseKey: context.cloudflare.env.VITE_SUPABASE_ANON_KEY,
    });

    const response = await supabase.auth.signInWithOtp({
        email: data.email,
        options: {
            captchaToken: data.captchaToken,
        },
    });

    if (response.error) {
        return json({
            errors: {} as Record<string, Record<string, string>>,
            supaBaseError: response.error.message,
            defaultValues,
        });
    }

    return redirect(`/auth/verify-email?email=${encodeURIComponent(data.email)}`, {
        headers,
    });
}

function LastUsedIndicator() {
    return (
        <span className="absolute -top-2 -right-2 bg-primary text-primary-foreground text-xs py-1 px-2 rounded-full transform rotate-12">
            Last used
        </span>
    );
}

export default function SignIn() {
    const actionData = useActionData<typeof action>();
    const navigation = useNavigation();
    const [captchaToken, setCaptchaToken] = useState<string>("");
    const { ENV } = useLoaderData<typeof loader>();
    const [lastUsed, setLastUsed] = useState<string>("");

    const isSubmitting = navigation.state === "submitting";

    const supabase = createBrowserClient(ENV.VITE_SUPABASE_URL, ENV.VITE_SUPABASE_ANON_KEY);

    useEffect(() => {
        setLastUsed(localStorage.getItem("last_used_provider") || "");
    }, []);

    function handleGoogleSignIn(): void {
        localStorage.setItem("last_used_provider", "google");
        supabase.auth.signInWithOAuth({
            provider: "google",
            options: {
                redirectTo: `${window.location.origin}/auth/callback`,
            },
        });
    }

    function handleLinkedinSignIn(): void {
        localStorage.setItem("last_used_provider", "linkedin");
        supabase.auth.signInWithOAuth({
            provider: "linkedin_oidc",
            options: {
                redirectTo: `${window.location.origin}/auth/callback`,
            },
        });
    }

    function handleSubmit() {
        localStorage.setItem("last_used_provider", "email");
        // Continue with the default form submission behavior
    }

    return (
        <div className="container relative min-h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
            <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
                <div className="absolute inset-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700" />
                <div className="relative z-20 flex items-center text-lg font-medium">
                    <img src="/logo-white.svg" alt="TailoredCV Logo" className="h-8 mr-2" />
                </div>
                <div className="relative z-20 mt-auto">
                    <h2 className="text-4xl font-bold mb-6">
                        The smartest way to create your professional CV
                    </h2>
                    <ul className="space-y-4 text-lg">
                        <li className="flex items-center">
                            <CheckCircle className="mr-2 h-5 w-5" />
                            AI-powered CV generation and optimization
                        </li>
                        <li className="flex items-center">
                            <CheckCircle className="mr-2 h-5 w-5" />
                            Professional templates for every industry
                        </li>
                        <li className="flex items-center">
                            <CheckCircle className="mr-2 h-5 w-5" />
                            Real-time feedback and suggestions
                        </li>
                        <li className="flex items-center">
                            <CheckCircle className="mr-2 h-5 w-5" />
                            Export to multiple formats
                        </li>
                    </ul>
                    <div className="mt-8 grid grid-cols-4 gap-4">
                        <div className="flex flex-col items-center justify-center rounded-lg bg-white/10 p-4">
                            <Sparkles className="h-5 w-5 mb-2" />
                            <p className="text-sm text-center">AI-Powered</p>
                        </div>
                        <div className="flex flex-col items-center justify-center rounded-lg bg-white/10 p-4">
                            <Target className="h-5 w-5 mb-2" />
                            <p className="text-sm text-center">ATS-Friendly</p>
                        </div>
                        <div className="flex flex-col items-center justify-center rounded-lg bg-white/10 p-4">
                            <Clock className="h-5 w-5 mb-2" />
                            <p className="text-sm text-center">Save Time</p>
                        </div>
                        <div className="flex flex-col items-center justify-center rounded-lg bg-white/10 p-4">
                            <Award className="h-5 w-5 mb-2" />
                            <p className="text-sm text-center">Stand Out</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:p-8">
                <Card className="mx-auto max-w-sm">
                    <CardHeader className="text-center">
                        <img src="/logo.svg" alt="TailoredCV Logo" className="h-16 mx-auto mb-4" />
                        <CardTitle>Continue to TailoredCV.ai</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="mt-6 space-y-2">
                            <Button
                                type="button"
                                className="w-full relative"
                                variant="outline"
                                onClick={handleGoogleSignIn}
                            >
                                <SiGoogle className="w-5 h-5 mr-2" />
                                Continue with Google
                                {lastUsed === "google" && <LastUsedIndicator />}
                            </Button>
                            <Button
                                type="button"
                                className="w-full relative"
                                variant="outline"
                                onClick={handleLinkedinSignIn}
                            >
                                <SiLinkedin className="w-5 h-5 mr-2" />
                                Continue with LinkedIn
                                {lastUsed === "linkedin" && <LastUsedIndicator />}
                            </Button>
                        </div>

                        <div className="relative my-4">
                            <div className="absolute inset-0 flex items-center">
                                <span className="w-full border-t" />
                            </div>
                            <div className="relative flex justify-center text-xs uppercase">
                                <span className="bg-background px-2 text-muted-foreground">or</span>
                            </div>
                        </div>

                        <Form className="space-y-6" method="post" onSubmit={handleSubmit}>
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="email">Email address</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        placeholder="Enter your email"
                                        defaultValue={actionData?.defaultValues?.email}
                                    />
                                </div>
                            </div>

                            {actionData && (actionData.errors || actionData.supaBaseError) && (
                                <Alert variant="destructive">
                                    <AlertDescription>
                                        {actionData.errors?.email?.message ||
                                            actionData.supaBaseError}
                                    </AlertDescription>
                                </Alert>
                            )}

                            <div>
                                <input type="hidden" name="captchaToken" value={captchaToken} />
                                <Turnstile
                                    className="mx-auto text-center"
                                    siteKey={ENV.VITE_TURNSTILE_SITE_KEY}
                                    onSuccess={(token) => {
                                        setCaptchaToken(token);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    className="w-full relative"
                                    variant="action"
                                    disabled={isSubmitting || !captchaToken}
                                >
                                    {isSubmitting ? "Signing in..." : "Continue"}
                                    {lastUsed === "email" && <LastUsedIndicator />}
                                </Button>
                            </div>
                        </Form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
